import react, { useRef, Suspense, useEffect } from "react";
// Gsap
import gsap from "gsap";
// Three
import * as THREE from "three";
// R3F and Drei
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
// Shaders
import DefaultMaterial from "src/shaders/transition";
import { useStore } from "@nanostores/react";
import { CurrProjectPage } from "@context/homePages";
import { ProjectPages } from "@context/server";

function Scene() {
  const { viewport } = useThree();
  const shaderMaterialRefDefault = useRef<typeof DefaultMaterial>(null);
  const textures = useTexture(ProjectPages.map((proj) => proj.image || ""));

  const currPage = useStore(CurrProjectPage);
  const currTexture = textures[currPage];

  var prevTexture = textures[(currPage + 1) % textures.length];

  useEffect(() => {
    if (!shaderMaterialRefDefault.current) return;
    gsap.to(shaderMaterialRefDefault.current, {
      // uProgress: currPage / ProjectPages.length,
      uProgress: currPage % 2,
      onStart: () => {
        if (shaderMaterialRefDefault.current) {
          // @ts-ignore
          shaderMaterialRefDefault.current.uTexture2 = currTexture;
        }
      },
    });
  }, [currPage]);

  return (
    <sprite scale={[viewport.width / 1, viewport.width / 2, 0]}>
      <defaultMaterial
        ref={shaderMaterialRefDefault}
        // @ts-ignore
        uProgress={0}
        uTexture1={currTexture}
        uTexture2={prevTexture}
        attach="material"
      />
    </sprite>
  );
}

export default function TestShaderThing() {
  return (
    <Canvas>
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
    </Canvas>
  );
}
